import Vue from 'vue';

import UploadDropArea from 'components/upload/UploadDropArea.vue';
import uploadStore from 'store/upload';
import { i18n } from 'locales';

export default function initializeUploadDropArea() {
  const mountElement = '#vue-upload-drop-area';
  const uploadArea = document.querySelector(mountElement);
  if (uploadArea === null) {
    return;
  }

  const store = uploadStore(null, uploadArea.dataset.newProjectPath);
  new Vue({
    i18n: i18n(),
    store,
    components: {
      UploadDropArea,
    },
  }).$mount(mountElement);
}
